.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.logo img {
  height: auto;
  width: 400px;
}

@media only screen and (max-width: 440px) {
  .logo img {
    width: 350px;
  }
}

@media only screen and (max-width: 350px) {
  .logo img {
    width: 300px;
  }
}